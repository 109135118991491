<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="上传中，请等待..."
  >
    <heads></heads>
    <!-- <ctdkbanners></ctdkbanners> -->
    <islogin />
    <div class="fgx"></div>
    <div class="content">
      <div class="cons">
        <div class="cons-title">本刊投稿评估</div>

        <div class="qkxq qkxq-wz">
          <div class="qkxq-title">期刊<span>详情</span></div>
          <div class="qkxq-con">
            <div class="qkxq-con-left">
              <div class="qkxq-con-left-img">
                <el-image
                  :src="srcurl"
                  alt=""
                  style="width: 200px; height: 280px"
                />
              </div>
              <div class="qkxq-con-left-xq">
                <div class="qkxq-con-left-xq-title">《{{ obj.Title }}》</div>
                <div
                  :class="
                    isqkjj == false
                      ? 'qkxq-con-left-xq-js-ss'
                      : 'qkxq-con-left-xq-js'
                  "
                >
                  {{ obj.qkjj }}
                  <div
                    class="qkxq-con-left-xq-js-ckgd"
                    @click="isqkjj = !isqkjj"
                  >
                    {{ isqkjj == false ? "展开" : "收起" }}
                  </div>
                </div>
                <div class="qkxq-con-left-xq-jscon">
                  <div class="qkxq-con-left-xq-jscon-list">
                    <span>出版周期：</span>{{ obj.cbzq }}
                  </div>
                  <div class="qkxq-con-left-xq-jscon-list">
                    <span>语种：</span>{{ obj.yuzhong }}
                  </div>
                  <div class="qkxq-con-left-xq-jscon-list">
                    <span>影响因子：</span>{{ obj.yxyz }}
                  </div>
                  <div class="qkxq-con-left-xq-jscon-list">
                    <span>国内刊号：</span>{{ obj.gnkh }}
                  </div>
                  <div class="qkxq-con-left-xq-jscon-list">
                    <span>国际刊号：</span>{{ obj.gjkh }}
                  </div>
                </div>
                <div class="qkxq-con-left-xq-jscon2">
                  <div
                    class="qkxq-con-left-xq-jscon2-list"
                    v-if="obj.zylm && obj.zylm != ''"
                    :title="obj.zylm"
                  >
                    <span>♦ 主要栏目：</span>{{ obj.zylm }}
                  </div>
                  <div
                    class="qkxq-con-left-xq-jscon2-list"
                    v-if="obj.Award && obj.Award != ''"
                    :title="obj.Award"
                  >
                    <span>♦ 获奖情况：</span>{{ obj.Award }}
                  </div>
                  <div class="qkxq-con-left-xq-jscon2-list">
                    <span>♦ 收录情况：</span>
                    <span v-for="(e, i) in obj.shouluqingkuang" :key="i">
                      <a
                        target="_blank"
                        v-if="e.split('：')[1] != ''"
                        style="margin-right: 10px"
                        :href="e.split('：')[1]"
                        >{{ e.split("：")[0] }}</a
                      >
                    </span>
                    <span class="tipsyuyan">
                      <span>&lt;</span>
                      <span>查看更新情况</span>
                    </span>
                  </div>
                  <div
                    class="qkxq-con-left-xq-jscon2-list"
                    v-if="obj.CorePeriodical && obj.CorePeriodical != ''"
                    :title="obj.CorePeriodical"
                  >
                    <span>♦ 期刊情况：</span>{{ obj.CorePeriodical }}
                  </div>
                  <div
                    class="qkxq-con-left-xq-jscon2-list"
                    v-if="obj.zgorg && obj.zgorg != ''"
                    :title="obj.zgorg"
                  >
                    <span>♦ 主管单位：</span>{{ obj.zgorg }}
                  </div>
                  <div
                    class="qkxq-con-left-xq-jscon2-list"
                    v-if="obj.zborg && obj.zborg != ''"
                    :title="obj.zborg"
                  >
                    <span>♦ 主办单位：</span>{{ obj.zborg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="qkxq-con-right">
              <div
                v-if="obj.kz13 != ''"
                class="qkxq-con-right-img"
                @click="clicktougaotuijian"
              >
                <el-image src="/img/detail/xiangtatougao.jpg" alt="" />
              </div>
              <div class="qkxq-con-right-lxfs">
                <div class="qkxq-con-right-lxfs-title">联系方式</div>
                <div class="qkxq-con-right-lxfs-con">
                  <div
                    class="qkxq-con-right-lxfs-list"
                    v-if="obj.zhubian && obj.zhubian != ''"
                  >
                    <span>主编：</span>{{ obj.zhubian }}
                  </div>
                  <div
                    class="qkxq-con-right-lxfs-list"
                    v-if="obj.tel && obj.tel != ''"
                  >
                    <span>电话：</span>{{ obj.tel }}
                  </div>
                  <div
                    class="qkxq-con-right-lxfs-list"
                    v-if="obj.yzbm && obj.yzbm != ''"
                  >
                    <span>邮编：</span>{{ obj.yzbm }}
                  </div>
                  <div
                    class="qkxq-con-right-lxfs-list"
                    v-if="obj.dzyx && obj.dzyx != ''"
                  >
                    <span>邮箱：</span>{{ obj.dzyx }}
                  </div>
                  <div
                    class="qkxq-con-right-lxfs-list"
                    v-if="obj.address && obj.address != ''"
                  >
                    <span>地址：</span>{{ obj.address }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="cons-title-subtitle">
          <p>
            必填内容：标题、摘要、关键词。选填内容：作者单位、论文正文。一键选刊：轻松提交，智能评估；领域关联，刊文整合；自动匹配，投稿直达
          </p>
        </div> -->
        <div class="cons-form">
          <el-form
            :model="form"
            label-width="120px"
            :rules="baseRules"
            ref="ruleFormRef"
          >
            <div class="custom-block">标题、摘要、关键词为必填信息。</div>
            <el-form-item label="标题" required prop="btword">
              <el-input
                v-model="form.btword"
                placeholder="请输入标题（5-100个字符）"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="摘要" required prop="zyword">
              <el-input
                v-model="form.zyword"
                type="textarea"
                placeholder="请输入摘要（50-2000个字符）"
                @keyup.ctrl.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="关键词" required prop="kwword">
              <el-input
                v-model="form.kwword"
                placeholder="请输入关键词，中间以逗号或空格或分号隔开（5-100个字符）"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <div class="custom-block">以下为选填信息。</div>

            <el-form-item label="作者单位">
              <el-input
                v-model="form.writerorg"
                placeholder="请输入作者单位"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="正文">
              <el-input
                v-model="form.zwword"
                placeholder="请输入正文"
                type="textarea"
                @keyup.ctrl.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <div class="con-btn">
              <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >立即提交</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();

const router = useRouter();

// 返回的数据
let obj = reactive({});
// 图片路径
let srcurl = ref("");
// 期刊简介展开
let isqkjj = ref(false);

// loading
let loading = ref(false);
let sbzczyval = ref("");
let form = reactive({
  btword: "",
  zyword: "",
  kwword: "",
  zwword: "",
  ckwx: "",
  writer: "",
  writerorg: "",
  jjkt: "",
  diqu: "",
  fbyt: "",
  sbzczy: "",
  sbzcjb: "",
  subxk: "",
});
const arealist = [
  { Id: 1, name: "安徽省" },
  { Id: 2, name: "北京市" },
  { Id: 3, name: "重庆市" },
  { Id: 4, name: "福建省" },
  { Id: 5, name: "广东省" },
  { Id: 6, name: "甘肃省" },
  { Id: 7, name: "广西区" },
  { Id: 8, name: "河南省" },
  { Id: 9, name: "黑龙江" },
  { Id: 10, name: "湖南省" },
  { Id: 11, name: "吉林省" },
  { Id: 12, name: "江苏省" },
  { Id: 13, name: "辽宁省" },
  { Id: 14, name: "宁夏省" },
  { Id: 15, name: "青海省" },
  { Id: 16, name: "山东省" },
  { Id: 17, name: "山西省" },
  { Id: 18, name: "陕西省" },
  { Id: 19, name: "天津市" },
  { Id: 20, name: "新疆省" },
  { Id: 21, name: "云南省" },
  { Id: 22, name: "浙江省" },
  { Id: 23, name: "上海市" },
  { Id: 24, name: "西藏省" },
  { Id: 25, name: "江西省" },
  { Id: 26, name: "四川省" },
  { Id: 27, name: "海南省" },
  { Id: 28, name: "河北省" },
  { Id: 29, name: "湖北省" },
  { Id: 30, name: "贵州省" },
  { Id: 31, name: "内蒙古" },
  { Id: 32, name: "其他" },
  { Id: 33, name: "台湾省" },
  { Id: 34, name: "港澳" },
];
const zczylist = [
  {
    value: "高等学校教师",
    label: "高等学校教师",
  },
  {
    value: "哲学社会科学研究人员",
    label: "哲学社会科学研究人员",
  },
  {
    value: "自然科学研究人员",
    label: "自然科学研究人员",
    children: [
      {
        value: "自然科学研究",
        label: "自然科学研究",
      },
      {
        value: "科学传播",
        label: "科学传播",
      },
    ],
  },
  {
    value: "卫生技术人员",
    label: "卫生技术人员",
    children: [
      {
        value: "医疗类",
        label: "医疗类",
      },
      {
        value: "药学类",
        label: "药学类",
      },
      {
        value: "护理类",
        label: "护理类",
      },
      {
        value: "技术类",
        label: "技术类",
      },
      {
        value: "基层定向",
        label: "基层定向",
      },
    ],
  },
  {
    value: "工程技术人员",
    label: "工程技术人员",
  },
  {
    value: "农业技术人员",
    label: "农业技术人员",
    children: [
      {
        value: "农艺",
        label: "农艺",
      },
      {
        value: "兽医",
        label: "兽医",
      },
      {
        value: "畜牧",
        label: "畜牧",
      },
      {
        value: "基层",
        label: "基层",
      },
    ],
  },
  {
    value: "新闻专业人员",
    label: "新闻专业人员",
    children: [
      {
        value: "记者",
        label: "记者",
      },
      {
        value: "编辑",
        label: "编辑",
      },
    ],
  },
  {
    value: "出版专业人员",
    label: "出版专业人员",
    children: [
      {
        value: "编审",
        label: "编审",
      },
    ],
  },
  {
    value: "图书资料专业人员",
    label: "图书资料专业人员",
    children: [
      {
        value: "图书资料",
        label: "图书资料",
      },
      {
        value: "群众文化",
        label: "群众文化",
      },
    ],
  },
  {
    value: "文物博物专业人员",
    label: "文物博物专业人员",
  },
  {
    value: "档案专业人员",
    label: "档案专业人员",
  },
  {
    value: "工艺美术专业人员",
    label: "工艺美术专业人员",
  },
  {
    value: "技工院校教师",
    label: "技工院校教师",
    children: [
      {
        value: "文化技术理论课",
        label: "文化技术理论课",
      },
      {
        value: "生产实习课",
        label: "生产实习课",
      },
    ],
  },
  {
    value: "体育专业人员",
    label: "体育专业人员",
    children: [
      {
        value: "教练员",
        label: "教练员",
      },
      {
        value: "运动防护师",
        label: "运动防护师",
      },
    ],
  },
  {
    value: "翻译专业人员",
    label: "翻译专业人员",
  },
  {
    value: "播音主持专业人员",
    label: "播音主持专业人员",
  },
  {
    value: "会计人员",
    label: "会计人员",
  },
  {
    value: "统计专业人员",
    label: "统计专业人员",
  },
  {
    value: "经济专业人员",
    label: "经济专业人员",
    children: [
      {
        value:
          "工商管理、农业经济、财政税收、金融、保险、运输经济、旅游经济、建筑与房地产经济",
        label:
          "工商管理、农业经济、财政税收、金融、保险、运输经济、旅游经济、建筑与房地产经济",
      },
      {
        value: "人力资源管理",
        label: "人力资源管理",
      },
      {
        value: "知识产权",
        label: "知识产权",
      },
    ],
  },
  {
    value: "实验技术人才",
    label: "实验技术人才",
  },
  {
    value: "中等职业学校教师",
    label: "中等职业学校教师",
    children: [
      {
        value: "文化课、专业课教师",
        label: "文化课、专业课教师",
      },
      {
        value: "实习指导教师",
        label: "实习指导教师",
      },
    ],
  },
  {
    value: "中小学教师",
    label: "中小学教师",
  },
  {
    value: "艺术专业人员",
    label: "艺术专业人员",
    children: [
      {
        value: "演员",
        label: "演员",
      },
      {
        value: "演奏员",
        label: "演奏员",
      },
      {
        value: "编剧",
        label: "编剧",
      },
      {
        value: "导演（编导）",
        label: "导演（编导）",
      },
      {
        value: "指挥",
        label: "指挥",
      },
      {
        value: "作曲",
        label: "作曲",
      },
      {
        value: "作词",
        label: "作词",
      },
      {
        value: "摄影（摄像）",
        label: "摄影（摄像）",
      },
      {
        value: "舞台美术设计",
        label: "舞台美术设计",
      },
      {
        value: "艺术创意设计",
        label: "艺术创意设计",
      },
      {
        value: "美术（含雕塑、书法、篆刻等）",
        label: "美术（含雕塑、书法、篆刻等）",
      },
      {
        value: "文学创作",
        label: "文学创作",
      },
      {
        value: "演出监督",
        label: "演出监督",
      },
      {
        value: "舞台技术",
        label: "舞台技术",
      },
      {
        value: "录音",
        label: "录音",
      },
      {
        value: "剪辑",
        label: "剪辑",
      },
    ],
  },
  {
    value: "公共法律服务专业人员",
    label: "公共法律服务专业人员",
    children: [
      {
        value: "公证",
        label: "公证",
      },
      {
        value: "司法鉴定（物证类、声像资料、环境损害）",
        label: "司法鉴定（物证类、声像资料、环境损害）",
      },
      {
        value: "司法鉴定（法医）",
        label: "司法鉴定（法医）",
      },
      {
        value: "律师",
        label: "律师",
      },
    ],
  },
  {
    value: "船舶专业技术人员",
    label: "船舶专业技术人员",
    children: [
      {
        value: "驾驶",
        label: "驾驶",
      },
      {
        value: "轮机",
        label: "轮机",
      },
      {
        value: "电子",
        label: "电子",
      },
      {
        value: "引航",
        label: "引航",
      },
    ],
  },
  {
    value: "民用航空飞行技术人员",
    label: "民用航空飞行技术人员",
    children: [
      {
        value: "驾驶",
        label: "驾驶",
      },
      {
        value: "领航",
        label: "领航",
      },
      {
        value: "通信",
        label: "通信",
      },
      {
        value: "机械",
        label: "机械",
      },
    ],
  },
  {
    value: "审计专业人员",
    label: "审计专业人员",
  },
  {
    value: "党校教师",
    label: "党校教师",
    children: [
      {
        value: "市级、区级",
        label: "市级、区级",
      },
      {
        value: "县级",
        label: "县级",
      },
    ],
  },
  {
    value: "技术经纪",
    label: "技术经纪",
    children: [
      {
        value: "自然科学研究人员",
        label: "自然科学研究人员",
      },
      {
        value: "工程技术人员",
        label: "工程技术人员",
      },
    ],
  },
];
const ruleFormRef = ref();

const baseRules = {
  btword: [{ required: true, message: "请输入标题", trigger: "blur" }],
  zyword: [{ required: true, message: "请输入摘要", trigger: "blur" }],
  kwword: [{ required: true, message: "请输入关键词", trigger: "blur" }],
};

const handleChange = (val) => {
  val.forEach((e, i) => {
    if (i == val.length - 1) {
      form.sbzczy += e;
    } else {
      form.sbzczy += e + ",";
    }
  });
};

// 获取数据
function getList() {
  get("/Journal/GetQkInfo", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    Object.assign(obj, res.data);
    obj.shouluqingkuang = obj.slqkdet.split(";\n");
    srcurl.value = `${proxy.$imgurl}/qikanfmpic/${obj.pycode}.jpg`;

    // 页面标题描述摘要
    document.title = res.data.seotitle;
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", res.data.seokeyword);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", res.data.kz11);
    // kz13如果存在邮箱 直接跳转投稿页
    if (obj.kz13 != "") {
      router.push({
        path: "/tougao",
        query: {
          id: router.currentRoute.value.query.id,
        },
      });
    }
  });
}
getList();

// 想他投稿
const clicktougaotuijian = () => {
  const url = router.resolve({
    path: "/tougao",
    query: {
      id: router.currentRoute.value.query.id,
    },
  });
  window.open(url.href);
};

// 提交代码
const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      loading.value = true;
      post("/journalRecommend/SubNewFive", form)
        .then((res) => {
          loading.value = false;
          if (res.code === 200) {
            ElMessage({
              message: "提交成功！",
              duration: 1000,
              onClose: () => {
                router.push({
                  path: "/xkzs/tgqk",
                  query: {
                    id: router.currentRoute.value.query.id,
                    tjid: res.data,
                  },
                });
              },
            });
          } else {
            ElMessage({
              message: "提交失败：" + res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }
  });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  min-height: 100px !important;
  font-size: 18px;
}
:deep(.cons-form .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 20px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.con-btn {
  text-align: center;
}
.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}
.fgx {
  width: 100%;
  border-top: 1px solid #1b91ff;
}

.qkxq {
  background: #fff;
}
.qkxq-wz {
  border: 1px solid #dcdfe6;
}
.qkxq-title {
  width: 100%;
  height: 60px;
  border-left: 2px solid #3860f3;
  box-sizing: border-box;
  padding-left: 20px;
  line-height: 60px;
  color: #3860f3;
  font-weight: bold;
  font-size: 24px;
}
.qkxq-title > span {
  color: #666;
}
.qkxq-con {
  position: relative;
}
.qkxq-con::after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left {
  width: 910px;
  height: auto;
  float: left;
  padding: 20px;
  box-sizing: border-box;
}
.qkxq-con-left:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-img {
  width: 200px;
  height: 280px;
  float: left;
  box-sizing: border-box;
  border: 1px solid #eee;
}
.qkxq-con-left-xq {
  width: 670px;
  height: auto;
  float: left;
  padding: 0 20px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  font-size: 14px;
}
.qkxq-con-left-xq-title {
  font-size: 20px;
  font-weight: bold;
}
.qkxq-con-left-xq-js {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 10px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: justify;
  margin-bottom: 20px;
  color: #888;
  line-height: 22px;
}
.qkxq-con-left-xq-js-ss {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  box-sizing: border-box;
  text-align: justify;
  margin-bottom: 30px;
  padding-right: 40px;
  color: #888;
  line-height: 22px;
  overflow: hidden;
}
.qkxq-con-left-xq-js-ckgd {
  position: absolute;
  right: 0;
  bottom: -4px;
  color: #3860f3;
  cursor: pointer;
}
.qkxq-con-left-xq-jscon {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.qkxq-con-left-xq-jscon:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-xq-jscon-list {
  float: left;
  width: 33.33%;
  display: flex;
  align-items: center;
  color: #3860f3;
  margin-bottom: 5px;
}
.qkxq-con-left-xq-jscon-list > span {
  width: 90px;
  display: block;
}
.qkxq-con-left-xq-jscon2:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-left-xq-jscon2-list {
  width: 100%;
  float: left;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.qkxq-con-left-xq-jscon2-list > span {
  width: 100px;
  color: #888;
  display: inline;
}
.qkxq-con-left-xq-jscon2-list-50 {
  width: 50%;
}
.qkxq-con-right {
  width: 287px;
  float: left;
  padding: 20px 20px 20px 0;
  box-sizing: border-box;
}
.qkxq-con-right-img {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  margin-bottom: 15px;
}
.qkxq-con-right-lxfs {
  width: 100%;
}
.qkxq-con-right-lxfs-con {
  font-size: 14px;
  padding-top: 10px;
}
.qkxq-con-right-lxfs-con:after {
  content: "";
  display: table;
  clear: both;
}
.qkxq-con-right-lxfs-list {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.qkxq-con-right-lxfs-title {
  font-size: 18px;
  font-weight: bold;
  color: #888;
}
</style>
